import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
declare var $: any, CryptoJS: any, window: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  tab = 'register';
  loginform: FormGroup;
  signupform: FormGroup;
  getFormError = getFormError;
  goBackAllowed = false;
  showForgotPassword = false;
  requestError = '';
  requestMessage = '';
  cameFrom = '';
  productId = '';
  LogoutOtherLogins : boolean = false;
  item:any = {CurrentLocation:{Error:''}, Origin:{Error:''}};
  constructor(private fb: FormBuilder, public cs: CommonService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.cs.spinner = false;

    if(this.route.snapshot != null && this.route.snapshot.queryParams != null && this.route.snapshot.queryParams.token != null
      && this.route.snapshot.queryParams.token != ''){
      this.showForgotPassword = true;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tab = window.history.state.page || 'register';
      }
    })
    if ($.fn.fullpage.destroy){
      $.fn.fullpage.destroy('all');
    }
    this.route.paramMap.pipe(map(() => window.history.state));
    //this.tab = window.history.state.page || 'register';
    this.tab = this.router.url.replace('/', '');
    if (window.history.state.backTo) {
      this.goBackAllowed = true;
      this.cameFrom = window.history.state.cameFrom || 'service';

      if (window.history.state.productId) {
        this.productId = window.history.state.productId;
      }

    }
    if (localStorage.getItem('productId')) {
      this.productId = localStorage.getItem('productId');
    }
    this.loginform = this.fb.group({
      Email: ['', [Validators.required, emailPhoneUseridValidator, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      password: ['', [Validators.required]]
    });

    this.signupform = this.fb.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      PhoneNumber: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      CurrentLocation: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Origin: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      /*
      password: ['', [Validators.required]],
      ConfirmPassword: ['', [Validators.required]],
      TermsAndCondition: ['', [Validators.required]],
      */
    });
  }




  showLogin(e) {
    this.showForgotPassword = e;
  }

  termsAndConditionAccepted(){
    this.cs.TermsAndConditionFlag = true;
    document.cookie = "TermsAndConditionFlag=" + this.cs.TermsAndConditionFlag.toString();             
  }

  updateLogoutOtherLogins(){
    this.LogoutOtherLogins = !this.LogoutOtherLogins;
  }

  loginsubmit() {

    this.requestError = '';
    this.requestMessage = '';

    this.requestError = '';
    //this.requestMessage = '';
    if (this.loginform.invalid) {
      this.loginform.markAllAsTouched();
      return;
    }
    let data = this.loginform.getRawValue();
    data.LoginDevice = 0; //0:Web, 1: App
    data.LogoutOtherLogins = this.LogoutOtherLogins;
    var model ={Username: data.Email, Password: data.password}
    this.cs.login(model).then((resLogin: any) => {
      if(resLogin.Status == 200){
        this.requestMessage = resLogin.Message + '... please wait';
        this.cs.loginSuccess(resLogin);
      }else{
        this.requestError = resLogin.Message;
      }
    }).catch((err) => {
      this.requestError = err.error.Message;
    })
  }

  handleCurrentLocationAddressChange(event) {
    var street_number = '';
    var route = '';

    if(this.item.CurrentLocation == null){
      this.item.CurrentLocation = {};
    }
    this.item.CurrentLocation.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.item.CurrentLocation.City = address.short_name;
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.item.CurrentLocation.State = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_2") != -1) {
        this.item.CurrentLocation.County = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        this.item.CurrentLocation.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.item.CurrentLocation.Country = address.short_name;
      }
    }
    if(this.item.CurrentLocation.Country == null || this.item.CurrentLocation.Country == undefined || this.item.CurrentLocation.Country == '' ||
    (this.item.CurrentLocation.Country != 'US' && this.item.CurrentLocation.Country != 'CA')) {
      this.item.CurrentLocation.City = '';
      return;
    }

    this.item.CurrentLocation.Lat = event.geometry.location.lat();
    this.item.CurrentLocation.Lng = event.geometry.location.lng();

    this.item.CurrentLocation.Street = street_number + ' ' + route;
    this.item.CurrentLocation.Zip = '';
    this.item.CurrentLocation.Error = '';
  }

  handleOriginAddressChange(event) {

    var street_number = '';
    var route = '';

    if(this.item.Origin == null){
      this.item.Origin = {};
    }
    this.item.Origin.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.item.Origin.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.item.Origin.State = address.short_name
      } else if (address.types.indexOf('postal_code') != -1) {
        this.item.Origin.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.item.Origin.Country = address.short_name;
      }
    }
    this.item.Origin.Street = street_number + ' ' + route;
    this.item.Origin.Zip = '';
    this.item.Origin.Error = '';

    if(this.item.Origin.Country == null || this.item.Origin.Country == undefined || this.item.Origin.Country == ''||
    this.item.Origin.Country != 'IN' ||
    this.item.Origin.State == null || this.item.Origin.State == undefined || this.item.Origin.State == ''||
    (this.item.Origin.State != 'BR' && this.item.Origin.State != 'JH' && this.item.Origin.State != 'UP' )) {
      this.item.Origin.City = '';
      return;
    }
    this.item.Origin.Lat = event.geometry.location.lat();
    this.item.Origin.Lng = event.geometry.location.lng();
  }

  signupsubmit() {

    this.requestError = '';
    this.requestMessage = '';

    let signupformdata = this.signupform.getRawValue();

    if (this.signupform.invalid) {
      this.signupform.markAllAsTouched();
      return;
    }
    /*
    if (signupformdata.password != signupformdata.ConfirmPassword) {
      $('.isamepassword').removeClass('hide');
      return;
    } else {
      $('.isamepassword').addClass('hide');
    }
    
    signupformdata.Password = signupformdata.password;
    delete signupformdata.password;
    */
    this.item.CurrentLocation.Error = '';
    this.item.Origin.Error = '';

    var addressSelected = true;
    if(this.item.CurrentLocation.City == null || this.item.CurrentLocation.City == undefined || this.item.CurrentLocation.City == '' ||
    this.item.CurrentLocation.Country == null || this.item.CurrentLocation.Country == undefined || this.item.CurrentLocation.Country == ''||
    (this.item.CurrentLocation.Country != 'US' && this.item.CurrentLocation.Country != 'CA')) {
      this.item.CurrentLocation.Error = 'Please select an US address from auto complete.';
      addressSelected = false;
    }
    if(this.item.Origin.City == null || this.item.Origin.City == undefined || this.item.Origin.City == '' ||
    this.item.Origin.Country == null || this.item.Origin.Country == undefined || this.item.Origin.Country == ''||
    this.item.Origin.Country != 'IN' ||
    this.item.Origin.State == null || this.item.Origin.State == undefined || this.item.Origin.State == ''||
    (this.item.Origin.State != 'BR' && this.item.Origin.State != 'JH' && this.item.Origin.State != 'UP' )) {
      this.item.Origin.Error = 'Please select an address from Bihar or Jharkhand from auto complete.';
      addressSelected = false;
    }
    if(!addressSelected) return;

    //signupformdata.CurrentLocation = this.item.CurrentLocation.City + ', ' + this.item.CurrentLocation.State + ', ' + this.item.CurrentLocation.Country;
    //signupformdata.Origin = this.item.Origin.City + ', ' + this.item.Origin.State + ', ' + this.item.Origin.Country;

    signupformdata.CurrentLocation = this.item.CurrentLocation;
    signupformdata.Origin = this.item.Origin;

    this.cs.request('/applicationuser/register', 'post', signupformdata).then((res: any) => {
      if (res.Status == 200) {
        this.requestMessage = res.Message + '... please wait';
        this.cs.loginSuccess(res);
      } else {
        this.requestError = res.Message;
      }
    }).catch((err) => {
      this.requestError = err;
    });

  }


  changeTab(tab) {
    this.tab = tab;
    if (this.tab == 'register') {
      this.signupform.reset();
    } else {
      this.loginform.reset();
    }
    this.requestError = '';
  }

  goBack() {
    if (this.cameFrom == 'product') {
      this.router.navigateByUrl('/product/' + window.history.state.productId);
    } else {
      this.router.navigateByUrl('/servicerequest', { state: { step: 4 } });
    }
  }

}
